.m {
  @apply bg-white text-dark rounded-[10px] w-full flex flex-col items-center justify-center gap-3 relative p-4 select-none cursor-pointer;
}

.n {
  @apply uppercase font-bold font-montserrat text-xs;
}

.d {
  @apply text-xs text-center -mt-2 text-darkGrey;
}
