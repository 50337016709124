@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-dark text-white font-openSans min-w-[250px];
  }

  :root {
    --color-primary: #0b1c2d;
  }
}
