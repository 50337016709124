.module {
  @apply w-full text-sm;

  &:nth-of-type(odd) {
    background: linear-gradient(to right, #fff 14%, #ededed 97%);
  }

  tr {
    &:first-child td {
      @apply pt-3 pb-0;
    }

    &:last-child td {
      @apply pb-5;
    }
  }

  td {
    @apply py-1;

    &:nth-child(1) {
      @apply text-start;
    }

    &:nth-child(2) {
      @apply text-end;
    }

    &:nth-child(3) {
      @apply pl-5 pr-1;
    }
  }
}

.main {
  @apply flex items-center;

  span {
    @apply block;
  }

  img {
    width: 70px;
  }
}

.prices {
  @apply flex items-end flex-col justify-center;
}

.info1 {
  @apply flex justify-between;
}

.info2 {
  @apply flex justify-between;
}

.trash {
  width: 70px;
}
