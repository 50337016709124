@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?2hn92");
  src: url("fonts/icomoon.eot?2hn92#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?2hn92") format("truetype"), url("fonts/icomoon.woff?2hn92") format("woff"),
    url("fonts/icomoon.svg?2hn92#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e900";
}
.icon-favourite:before {
  content: "\e901";
}
.icon-next:before {
  content: "\e902";
}
.icon-previous:before {
  content: "\e904";
}
.icon-swipe:before {
  content: "\e906";
}
.icon-touch:before {
  content: "\e907";
}
.icon-trash:before {
  content: "\e908";
}
.icon-up:before {
  content: "\e909";
}
