.m {
  @apply h-full w-full max-w-[600px] pt-[20px] mt-4 md:mt-0 transition duration-500 grid mx-auto;
  grid-template-rows:
    clamp(64px, 20%, 90px)
    clamp(64px, 20%, 90px)
    clamp(64px, 20%, 90px)
    clamp(64px, 20%, 90px)
    clamp(64px, 20%, 90px);
  grid-template-columns: auto auto auto auto auto;
  background: url(../../assets/images/stand-left.png) no-repeat 10% 0,
    url(../../assets/images/stand-right.png) no-repeat 90% 0;
  background-size: contain;
}
