.m {
  @apply relative;

  &:not(:hover) {
    .l {
      @apply opacity-0 invisible;
    }
  }
}

.l {
  @apply bg-dark text-white rounded block z-10 transition absolute top-1/2 -translate-y-1/2 py-0.5 px-1 text-xs;
  left: calc(100% + 10px);
  width: max-content;

  &:before {
    @apply w-0 h-0 left-[-5px] absolute;
    content: "";
    border: solid rgba(0, 0, 0, 0);
    border-width: 5px 5px 5px 0;
    @apply border-r-dark;
    top: calc(50% - 5px);
  }
}
