.module {
  @apply fixed z-[51] bottom-6 left-1/2 cursor-pointer;
  transform: translateX(calc(clamp(-300px, -50vw, -150px) + 20px));

  em {
    @apply text-[1.25rem] transition-colors;

    &:not(.dark) {
      text-shadow: rgba(0, 0, 0, 1) 1px 1px 2px;
    }

    &.dark {
      text-shadow: rgba(255, 255, 255, 1) 1px 1px 2px;
    }
  }
}

.dark {
  @apply text-dark;
}

.counter {
  @apply inline-flex justify-center items-center bg-yellow w-4 h-4 rounded-[50%] text-xs relative right-2 bottom-[10px] select-none;
}
