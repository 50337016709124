.m {
  @apply w-full h-full bg-dark transition duration-500;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 991px) {
    @apply fixed top-[60px] -translate-x-1/2 left-1/2 z-20;
    background-image: url("../../assets/images/bg.jpg");
  }

  @media (min-width: 992px) {
    @apply grid;
    min-height: calc(100vh - 60px);
    grid-template-rows: max-content auto;
    background: url("../../assets/images/bg.jpg");
  }

  &:not(.active) {
    @apply opacity-0;
  }
}

.lead {
  @apply max-w-[1200px] mx-auto mt-12 mb-8 px-4 text-center;

  h1 {
    @apply text-[30px] mb-2 text-white font-bold;
    line-height: 1.2;
  }

  p {
    @apply text-dark;
  }
}

.stands {
  @apply w-full h-full flex mx-auto transition duration-500 overflow-hidden overflow-x-auto;

  @media (min-width: 992px) {
    padding-left: calc((100vw - 1173px) / 2);
    padding-right: calc((100vw - 1173px) / 2);
  }
}

.item {
  @apply flex-shrink-0 flex-grow-0 px-2 relative basis-full md:basis-[391px] mb-10 md:mb-8 grid;

  @media (min-width: 992px) {
    grid-template-rows: 121px auto;
  }

  &:after {
    content: "";
    @apply absolute left-[2%] right-[2%] -bottom-[2%] top-0 block -z-10 bg-dark bg-opacity-[0.45] blur-[22px] origin-bottom;
    transform: perspective(300px) rotate3d(1, 0, 0, 22deg);
    backface-visibility: hidden;
  }
}

.dots {
  @apply absolute left-1/2 flex gap-2 z-30 -translate-x-1/2;
}

.dot {
  @apply w-10 h-10 flex justify-center items-center;

  span {
    @apply bg-white text-white w-5 h-5 transition border-white border-4 text-transparent;
  }

  &:not(.dotActive) {
    cursor: pointer;
  }

  &:not(.dotActive):not(:hover) {
    span {
      @apply bg-transparent;
    }
  }
}

.closeButton {
  @apply text-white select-none cursor-pointer absolute bottom-[15px] p-4 duration-200 scale-0 left-1/2 -translate-x-1/2 z-30;

  .active & {
    @apply scale-100 delay-500;
  }
}
