@import "icons";

* {
  @media (min-width: 768px) {
    scrollbar-width: 5px;
    scrollbar-color: rgba(0, 0, 0, 1);

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-dark;
    }
  }
}
