.module {
  @apply z-50 fixed top-0 left-0 w-full h-full flex justify-center bg-black overflow-hidden bg-opacity-0 transition duration-500 pointer-events-none;
}

.overlay {
  @apply bg-opacity-50 transition duration-500;

  .expandButton,
  .closeButton {
    @apply scale-100 delay-500;
  }

  .hide {
    transition-delay: 0ms;
  }
}

.box {
  @apply w-full max-w-[600px] max-h-full min-h-[390px] flex flex-col items-center text-center bg-white rounded-t-xl absolute top-full px-4 pt-5 py-20 -translate-y-full transition-all duration-500 pointer-events-auto;

  &.hasZenitInfo {
    @media (max-width: 500px) {
      min-height: 470px;
    }

    @media (min-width: 501px) {
      min-height: 450px;
    }
  }
}

.contentWrapper {
  @apply overflow-y-auto overflow-x-hidden flex flex-col items-center w-full relative;

  h1 {
    @apply text-dark text-xl;
  }

  h2 {
    @apply text-dark uppercase font-bold text-xs mt-1;
    letter-spacing: 0.1em;
  }

  > img {
    width: 140px;
  }
}

.expandButton {
  @apply w-[42px] h-[42px] text-sm rounded-full bg-white absolute bottom-[370px] flex justify-center text-dark duration-200 scale-0 cursor-pointer pointer-events-auto;

  .hasZenitInfo + & {
    @media (max-width: 500px) {
      bottom: 450px;
    }

    @media (min-width: 501px) {
      bottom: 430px;
    }
  }

  &:before {
    @apply relative top-2;
  }
}

.closeButton {
  @apply text-dark select-none cursor-pointer absolute bottom-[15px] p-4 duration-200 scale-0 pointer-events-auto;
}

.animShow {
  @apply -translate-y-[390px] transition duration-500;

  &.hasZenitInfo:not(.expanded) {
    @media (max-width: 500px) {
      --tw-translate-y: -470px;
    }

    @media (min-width: 501px) {
      --tw-translate-y: -450px;
    }
  }
}

.expanded {
  @apply -translate-y-full transition duration-500 delay-150;
}

.animHide {
  @apply translate-y-full delay-150;
}

.badgeWrapper {
  @apply absolute top-[50px] left-4 flex flex-col gap-1;

  img {
    @apply w-6;
  }
}

.buttonWrapper {
  @apply w-full mb-auto flex flex-col gap-3;

  span {
    @apply block py-3 text-xs;
  }
}

.loaderWrapper {
  @apply w-full h-[45%] mt-10 flex justify-center items-center;
}

.hide {
  @apply opacity-0 transition-opacity duration-200;
}

.zenitInfo {
  @apply text-dark text-xs mb-4;
}
