.m {
  @apply text-white text-center;

  @media (max-width: 991px) {
    @apply pt-14 pb-10;
  }

  @media (min-width: 992px) {
    @apply pt-20 pb-20;
  }
}

.menu {
  @apply flex list-none justify-center uppercase font-bold;

  @media (max-width: 991px) {
    @apply mb-5 flex-col;
  }

  @media (min-width: 992px) {
    @apply mb-10;
  }

  li {
    @media (max-width: 991px) {
      @apply my-2;
    }

    @media (min-width: 992px) {
      &:not(:first-child) {
        @apply border-l-2;
      }
    }

    a {
      @apply px-5 py-1;

      &:hover {
        @apply underline;
      }
    }
  }
}

.yco {
  @apply inline-block;

  &:hover {
    .yco_y path {
      fill: #ff5a58;
    }

    .yco_c path {
      fill: #58e4c4;
    }

    .yco_o path {
      fill: #69c3ff;
      @apply delay-200;
    }
  }

  svg {
    @apply w-[40px] h-auto;
  }
}

.yco_y,
.yco_c,
.yco_o {
  path {
    @apply transition duration-300;
  }
}

.yco_y {
  path {
    @apply delay-200;
  }
}

.yco_c {
  path {
    @apply delay-100;
  }
}
