.loader {
  img {
    @apply animate-load;
  }
}

.icon {
  width: 125px;
}

.favicon {
  width: 50px;
}
