.module {
  @apply w-full relative flex gap-4 mb-3;
}

.loaderWrapper {
  @apply min-h-[200px] flex justify-center items-center;
  width: calc(100vw - 32px);
}

.moreReferences {
  @apply absolute py-2 px-5 bg-red uppercase font-bold text-xs top-1/2 -translate-y-1/2 w-max whitespace-normal;
  max-width: calc(100% - 94px);
}

.blur {
  @apply blur-xs;
}
