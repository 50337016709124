.c {
  margin-top: 60px;

  @media (max-width: 991px) {
    @apply pt-3;
  }

  @media (min-width: 992px) {
    min-height: calc(100vh - 60px);
  }
}

.l {
  @apply gap-[10px] flex flex-wrap justify-center mt-32;
}
