.b {
  @apply text-white leading-4 uppercase font-semibold select-none cursor-pointer;
}

.d {
  @apply bg-dark;
}

.r {
  @apply bg-red;
}
