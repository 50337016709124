.module {
  @apply overflow-hidden;
}

.inner {
  @apply whitespace-nowrap transition-transform duration-500 select-none;
}

.item {
  @apply w-full min-h-[170px] max-h-[190px] sm:min-h-[200px] sm:max-h-[318px] inline-flex justify-center items-center overflow-hidden;

  img {
    @apply w-full h-full object-center object-cover;
  }
}

.button {
  @apply absolute text-xxl;
  top: calc(50% - 16px);

  &:first-of-type {
    left: 0;
  }

  &:last-of-type {
    right: 0;
  }
}

.previous,
.next {
  @apply w-[36px] h-[36px] text-xs bg-darkGrey flex items-center justify-center;
}
