.module {
  @apply text-dark w-full flex justify-around items-center mb-6;

  > div {
    @apply flex flex-col basis-full;

    span {
      @apply font-semibold;
    }

    p {
      @apply text-xs text-grey;
    }
  }
}

.expanded {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  > div {
    @apply border-light p-3;

    &:nth-child(-n + 3) {
      @apply border-b-4;
    }

    &:nth-child(3n + 1) {
      @apply border-r-4;
    }
  }
}

.separator {
  @apply h-full w-2 bg-light;
}

.highlight {
  @apply text-red;
}

.piecesSize {
  @apply text-xs;
}

.hide {
  @apply hidden;
}
