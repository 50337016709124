.module {
  @apply z-50 fixed top-[60px] bottom-0 left-0 right-0;
}

.wrapper {
  @apply w-full flex justify-center items-center relative;
}

.button {
  @apply w-[75px] h-[75px] justify-center items-center bg-white rounded-full select-none opacity-0 cursor-default;

  em {
    @apply text-[33px] text-dark;
  }
}

.bar {
  @apply w-full h-[40px] absolute bottom-0 bg-white rounded-t-2xl flex justify-center items-center text-dark text-xs px-1 text-center select-none transition translate-y-[40px] duration-500;
}

.positionIcon {
  @apply right-[2px] relative;
}

.animSwipe {
  @apply animate-swipe;
}

.animTouch {
  @apply animate-touch;
}

.animBar {
  @apply translate-y-0;
}
