.m {
  @apply bg-white flex flex-col pt-12 items-center px-5 lg:pt-20;
  min-height: calc(100vh - 60px);

  img {
    @apply mb-8;
  }

  h1,
  h2 {
    @apply text-dark text-center;
  }

  h1 {
    @apply text-xxl mb-2 font-light;
  }

  h2 {
    @apply text-lg mb-8;
  }
}

.b {
  a {
    @apply px-6 py-4 text-sm;
  }
}
