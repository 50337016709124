.m {
  @apply w-full mt-3;

  label {
    @apply text-sm font-bold;
  }
}

.button {
  @apply w-full;

  span {
    @apply block py-3 text-xs;
  }
}

.checkbox:checked:before {
  content: "\2714";
}

.checkbox {
  appearance: none;
  width: 1em;
  height: 1em;
  border: 2px solid black;
  border-radius: 3px;
  margin: 0 1px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    background: white;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:checked:before {
    content: "✔";
    color: black;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 15px;
    line-height: 0.75;
  }
}
