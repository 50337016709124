.module {
  @apply z-50 fixed top-0 left-0 w-full h-full flex justify-center bg-black overflow-hidden bg-opacity-0 transition duration-500 pointer-events-none;

  &:not(.overlay) {
    @apply invisible pointer-events-none;
  }

  &.overlay {
    @apply bg-opacity-50 transition duration-500;

    .close {
      @apply scale-100 delay-500;
    }

    .hide {
      transition-delay: 0ms;
    }
  }
}

.box {
  @apply w-full max-w-[600px] max-h-full min-h-[370px] flex flex-col items-center text-center bg-white text-dark rounded-t-xl absolute top-full px-4 pt-5 py-20 translate-y-full transition-all duration-500 pointer-events-auto;

  &.animShow {
    @apply -translate-y-full transition duration-500;
  }

  &.animHide {
    @apply translate-y-full delay-150;
  }
}

.content {
  @apply overflow-auto flex flex-col items-center w-full relative;

  h1 {
    @apply text-dark text-xl;
  }

  > img {
    width: 140px;
  }
}

.close {
  @apply text-dark select-none cursor-pointer absolute bottom-[15px] p-4 duration-200 scale-0 pointer-events-auto;
}
