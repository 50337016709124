.m {
  @apply cursor-pointer;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.55));

  img {
    transform: scale(1.05);
  }
}

.flat {
  @for $i from 1 through 25 {
    &:nth-child(#{$i}) {
      z-index: floor(calc((25 - $i) / 5)) * 10 + (($i - 1) % 5);
    }
  }

  @for $i from 1 through 5 {
    &:nth-last-child(#{$i}) {
      filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.45));
    }
  }
}

.waved {
  @for $i from 1 through 25 {
    &:nth-child(#{$i}) {
      z-index: floor(calc((25 - $i) / 5)) * 10 + (4 - (($i - 1) % 5));
    }
  }
}
